import React, {useEffect, useState} from 'react'
import {postOhadarbLaw, putOhadarbLaw} from "../../queries";
import {uploadFileWithOutUuid} from "../../services/awsS3Services";
import {ohadaData} from "../../util/type/types";
import {useTranslation} from "gatsby-plugin-react-i18next";
export const AddLaw = ({
                           name,
                           selectId = 0,
                           initData
                       }: { name: string, selectId: number, initData: ohadaData }) => {
    const {t} = useTranslation()
    const selectOption = [
        {
            name: t('OHADALaws'),
            value: 'ohada-laws'
        },
        {
            name: t('Bilateral Investment Treaties'),
            value: 'Bilateral-Investment-Treaties'
        },
        {
            name: t('Multilateral Investment Treaties'),
            value: 'Multilateral-Investment-Treaties'
        },
        {
            name: t('nationalLaw'),
            value: 'national-laws'
        }
    ]
    const isInNationalLaw = typeof window === 'object' && window?.location.pathname.includes('nationalLaw')
    // const isInOhadarbLaws = typeof window === 'object' && window?.location.pathname.includes('ohadarbLaws')
    const initialData = {
        title: '',
        adoptedAt: '',
        type: selectOption.filter((item, id) => selectId < 4 ? (id === selectId) : (id === 0))[0].value,
        place: '',
        journalNumber: '',
        files: [],
        dateOfJournal: '',
        docFileName: '',
        treatWith: '',
    }
    const [lawData, setLawData] = useState<any>(initialData)
    const {i18n} = useTranslation()
    const [folderName, setFolderName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formStatus, setFormStatus] = useState(false);
    const handleFileChange = (e: any) => {
        const {files, name} = e.target;
        setLawData((prevState: any) => ({
            ...prevState,
            [name]: files,
            docFileName: files[0].name // files[0].name.split('.').shift() + '.' + files[0].name.split('.').pop(),
        }));

    };
    const handleChange = (e: { target: { name: string; value: any; }; }) => {
        const {name, value} = e.target;
        name === 'type' && setFolderName(e.target.value === 'national-laws' ? 'nationalLaws'
            : e.target.value === 'Bilateral-Investment-Treaties' ? 'nationalLaws/BilateralInvestmentTreaties'
                : e.target.value === 'Multilateral-Investment-Treaties' ? 'nationalLaws/MultilateralInvestmentTreaties' : 'ohadArbLaws')

        setLawData((prev: any) => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }
    useEffect(() => {
        setLawData(initData !== undefined && initData.dateOfJournal !== undefined ? {
            ...initData,
            ...(initData.type === undefined &&
                { type: selectOption.filter((item, id) => selectId < 4 ? (id === selectId) : (id === 0))[0].value}),
            ...(isInNationalLaw && initData.journalNumber === undefined && {
                journalNumber: "Signed",
            })
        } : {...initialData}
        )
        setFolderName(initialData.type === 'national-laws' ? 'nationalLaws'
            : initialData.type === 'Bilateral-Investment-Treaties' ? 'nationalLaws/BilateralInvestmentTreaties'
                : initialData.type === 'Multilateral-Investment-Treaties' ? 'nationalLaws/MultilateralInvestmentTreaties' : 'ohadArbLaws')
    }, [initData, name])

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        setIsLoading(true);
        e.preventDefault();
        if (lawData.type === '' || (lawData.type === 'In force' && lawData.adoptedAt === '') || lawData.place === '' || lawData.journalNumber === ''
            || lawData.dateOfJournal === '' || lawData.docFileName === '') {
            alert(t('Please fill all the fields'));
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        if (initData && initData?.id) {
            console.log("start update")
            if (lawData?.files?.length) {
                uploadFileWithOutUuid(folderName, lawData.docFileName, lawData.files[0], (err, res) => {
                    if (err) {
                        alert(t('Something went wrong'));
                        setIsLoading(false);
                        console.log(err.message)
                    } else {
                        putOhadarbLaw({
                                ...lawData,
                                docFileName: res.Location,
                                lang: i18n.language,
                                ...(lawData.journalNumber === "In force" ?
                                    {adoptedAt: new Date(lawData.adoptedAt).toISOString()}
                                    : {adoptedAt: null})
                            },
                            (res: any) => {
                                if (res.id) {
                                    setFormStatus(true);
                                    // alert('Successfully added');
                                    setLawData(initialData)
                                    window.location.reload();
                                } else {
                                    alert(t('Something went wrong'));
                                }
                            })
                    }
                    setIsLoading(false);
                })
            } else {
                putOhadarbLaw({
                        ...lawData,
                        lang: i18n.language,
                        ...(lawData.journalNumber === "In force" ?
                            {adoptedAt: new Date(lawData.adoptedAt).toISOString()}
                            : {adoptedAt: null})
                    },
                    (res: any) => {
                        if (res.id) {
                            setFormStatus(true);
                            // alert('Successfully added');
                            setLawData(initialData)
                            window.location.reload();
                        } else {
                            alert(t('Something went wrong'));
                        }
                        setIsLoading(false);
                    })
            }
        } else {
            console.log("start upload")
            if (lawData?.files?.length === 0) {
                alert(t('Please upload a file'));
                setIsLoading(false);
                return;
            }
            uploadFileWithOutUuid(folderName, lawData.docFileName, lawData.files[0], (err, res) => {
                if (err) {
                    alert(t('Something went wrong'));
                    setIsLoading(false);
                    console.log(err.message)
                } else {
                    postOhadarbLaw({
                        ...lawData,
                        lang: i18n.language,
                        docFileName: res.Location,
                        ...(lawData.journalNumber === "In force" ?
                            {adoptedAt: new Date(lawData.adoptedAt).toISOString()}
                            : {adoptedAt: null})
                    }, (res: any) => {
                        if (res.id) {
                            setFormStatus(true);
                            // alert('Successfully added');
                            setLawData(initialData)
                            window.location.reload();
                        } else {
                            alert(t('Something went wrong'));
                        }
                        setIsLoading(false);
                    })
                    console.log("end upload")
                }
            })
        }
    }
    return (
        <>
            <div className="modal fade" id={`modal-add${selectId}`} tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content text-center">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            <h2 className="mb-3 text-start">{t('Add / Edit')} {name}</h2>
                            <p className="lead mb-6 text-start">{t('Add / Edit')} {t('takes less than 5 minutes.')}</p>
                            <form className="text-start mb-3"
                                  acceptCharset="UTF-8"
                                  method="POST"
                                  encType="multipart/form-data"
                                  id="gatsbyForm"
                                  onSubmit={handleSubmit}
                            >
                                <div className="form-floating mb-4">
                                    <input type="text" className="form-control"
                                           placeholder={t("Title")}
                                           value={lawData?.title || ''}
                                           onChange={handleChange}
                                           id="title"
                                           name="title"
                                    />
                                    <label htmlFor="title">{t('Title')}</label>
                                </div>
                                <div className="form-floating mb-4">
                                    <input type="text"
                                           value={lawData?.place || ''}
                                           onChange={handleChange}
                                           name="place"
                                           className="form-control" placeholder={t("Place")}
                                           id="place"/>
                                    <label htmlFor="place">{t("Place")}</label>
                                </div>
                                {isInNationalLaw ?
                                    <div className="form-select-wrapper mb-4">
                                        <select className="form-select" name="journalNumber"
                                                required
                                                aria-label={t("Select a Status")}
                                                onChange={handleChange}
                                                value={lawData.journalNumber || "Signed"}
                                        >
                                            <option value="Signed">{t("Signed")}</option>
                                            <option value="In force">{t('In force')}</option>
                                        </select>
                                    </div>
                                    :
                                    <div className="form-floating mb-4">
                                        <input type="text" className="form-control"
                                               value={lawData?.journalNumber || ''}
                                               onChange={handleChange}
                                               name="journalNumber"
                                               placeholder={t('journal Number')}
                                               id="journalNumber"/>
                                        <label htmlFor="journalNumber">{t('journal Number')}</label>
                                    </div>
                                }
                                {isInNationalLaw && lawData.journalNumber === "In force" ?
                                    <div className="form-floating mb-4">
                                        <input type="date" className="form-control"
                                               value={lawData?.adoptedAt || ''}
                                               name="adoptedAt"
                                               onChange={handleChange}
                                               placeholder={t("entry into Force")}
                                               id="adoptedAt"/>
                                        <label htmlFor="adoptedAt">{t("entry into Force")}</label>
                                    </div>
                                    : !isInNationalLaw &&
                                    <div className="form-floating mb-4">
                                        <input type="date" className="form-control"
                                               value={lawData?.adoptedAt || ''}
                                               name="adoptedAt"
                                               onChange={handleChange}
                                               placeholder={t('adoptedAt')} id="adoptedAt"/>
                                        <label htmlFor="adoptedAt">{t('adoptedAt')}</label>
                                    </div>
                                }
                                <div className="form-floating mb-4">
                                    <input type="date" className="form-control"
                                           value={lawData?.dateOfJournal || ''}
                                           onChange={handleChange}
                                           name="dateOfJournal"
                                           placeholder={isInNationalLaw ? t("Date of Signature") : t("Date Of Journal")}
                                           id="dateOfJournal"/>
                                    <label
                                        htmlFor="dateOfJournal">{isInNationalLaw ? t("Date of Signature") : t("Date Of Journal")}</label>
                                </div>
                                <div className="form-select-wrapper mb-4">
                                    <select className="form-select" name="type"
                                            required
                                            aria-label="Select a type"
                                            onChange={handleChange}
                                            value={lawData.type || initialData.type}
                                    >
                                        {selectOption.map((item, id: number) => {
                                            return (
                                                <option
                                                    key={id}
                                                    value={item.value}>
                                                    {item.name}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>

                                {lawData.type !== "ohada-laws" &&
                                    <div className="form-floating mb-4">
                                        <input type="text" className="form-control"
                                               value={lawData?.treatWith || ''}
                                               onChange={handleChange}
                                               name="treatWith"
                                               placeholder={t("treat With")}
                                               id="treatWith"/>
                                        <label htmlFor="treatWith">{t("treaty With")}</label>
                                    </div>

                                }
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-text">{t("upload the File")}</h5>
                                        <div className="form-group">
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="files"
                                                id="files"
                                                placeholder={t("upload the File")}
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {formStatus ? (
                                    <div className="text-success mb-2">
                                        {t('Your ohadArb Law has been added.')}
                                    </div>
                                ) : (
                                    ""
                                )}
                                {!isLoading ? (
                                        <button
                                            className="btn btn-primary rounded-pill btn-login w-100 mb-2" type="submit">
                                            <span className="d-flex flex-row justify-content-center align-items-center">
                                            <span className="btn-icon">
                                                <i className="fs-30 px-2 uil uil-cloud-upload"></i>
                                            </span>
                                                {t('Add')}
                                        </span>
                                        </button>)
                                    :
                                    (<button
                                            disabled={isLoading}
                                            className="btn btn-primary rounded-pill btn-login w-100 mb-2">
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">{t('Loading')}...</span>
                                            </div>
                                        </button>
                                    )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
