import React from 'react'
import {useTranslation} from "gatsby-plugin-react-i18next";


export const DeleteModal = ({name='Item', id, deleteFunction}: {name: string, id: string, deleteFunction: any}) => {

    const {t, i18n} = useTranslation()

    return (
        <div className="modal fade" id="modal-delete" tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content text-center">
                    <div className="modal-body">
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        <h2 className="mb-3 text-start">{t('Delete')} {name}</h2>
                        <h3>{t('Are you absolutely sure ?')}</h3>
                        <p className="lead mb-6 text-start">
                            {t('deleteMessage')}
                        </p>
                        <div className="d-flex flex-row justify-content-between mx-4">
                            <button className="btn btn-ash rounded-pill btn-login w-70 mb-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                            >
                                {t('Cancel')}
                            </button>
                            <button className="btn btn-danger rounded-pill btn-login w-70 mb-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        deleteFunction(id)
                                    }}
                            >
                                {t('Delete')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
