import React, {useState} from "react";
import LawsHeader from "./LawsHeader";
import LawsList from "./LawsList";


const LawsContent = ({ ohadArbLaws }:any) => {

    const [selected, setSelected] = useState<any>(null)
    return (
        <>
            <LawsHeader selectId={0} setSelected={setSelected}/>
            <LawsList setSelected={setSelected} selected={selected} ohadArbLaws={ohadArbLaws} selectId={0} />
            {/*<AddLaw  name="Ohadarb Law" selectId={0}/>*/}

        </>
    )
}

export default LawsContent;
