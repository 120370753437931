import {loadScript} from "../../util/helper";
import Layout from "../../components/layout/Layout";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";
import React from "react";
import LawsContent from "../../components/laws/LawsContent";
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {getOhadarbLaws} from "../../queries";


const OhadarbLaws = ({}: any) => {

    const [ohadArbLaws, setOhadArbLaws] = useState<any>(null);
    const {t, i18n} = useTranslation()

    useEffect(() => {

        getOhadarbLaws(setOhadArbLaws, i18n.language)
        loadScript("/assets/js/plugins.js");
        let timer = setTimeout(() => {
            loadScript("/assets/js/theme.js");
        }, 500)
        return () => clearTimeout(timer)
    }, [])

    return (
        <>
            <Helmet>
                <title>OHADARB | {t('ohadarbLaws')}</title>
            </Helmet>
            <Layout title={`OHADARB | ${t('Laws')}`}>
                {ohadArbLaws === null ?
                    <div className="page-loader"></div> :
                    <LawsContent ohadArbLaws={ohadArbLaws?.sort((a: any, b: any) => a.title > b.title ? 1 : -1)}/>
                }
            </Layout>
        </>
    )
}

export default OhadarbLaws;


export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
