import React, {useState} from 'react'
import CardType1 from "../card/CardType1";
import {deleteOhadarbLaws} from "../../queries";
import {AddLaw} from "./AddLaw";
import {DeleteModal} from "../modal/deleteModal";
import {initialO} from "../../util/type/types";
import {useTranslation} from "gatsby-plugin-react-i18next";

const LawsList = ({ohadArbLaws, selected, setSelected, selectId}: any) => {

    const {t} = useTranslation()
    return (
        <section className="wrapper bg-gradient-primary">
            <div className="container py-14 pt-md-15 pb-md-18">
                <div className="d-flex flex-row flex-wrap order-3 p-3 justify-content-start align-items-start"
                >
                    {ohadArbLaws
                        ?.map((law: { title: string; adoptedAt: any; place: string; journalNumber: any; id: string; }, idx: number) => {
                                return (
                                    <CardType1
                                        title={law.title}
                                        adopted={law.adoptedAt ? new Date(law.adoptedAt).toISOString().split('T')[0] : ''}
                                        place={law.place}
                                        selectId={selectId}
                                        journalNumber={law.journalNumber}
                                        link={`/app/ohadarbLaws/${law.id}`}
                                        key={idx}
                                        lawId={parseInt(law.id)}
                                        lawKey={String(idx + 1)}
                                        getSelected={(id: number) => {
                                            setSelected(ohadArbLaws.filter((item: { id: any; }) => parseInt(item.id) === id)[0])
                                        }}
                                    />
                                )
                            }
                        )}
                </div>

                {/*delete modal*/}
                <DeleteModal name={t('Ohadarb Laws')} id={selected?.id} deleteFunction={(id: number) => {
                    deleteOhadarbLaws(id)
                }}/>

                <AddLaw name={location.pathname.includes('ohadarbLaws') ? t('Ohadarb Laws') : t('nationalLaw')}
                        selectId={selectId}
                        initData={selected ? {
                            id: selected.id,
                            title: selected.title,
                            adoptedAt: selected.adoptedAt && new Date(selected.adoptedAt).toISOString().split('T')[0],
                            type: 'ohada-laws',
                            place: selected.place,
                            journalNumber: selected.journalNumber,
                            dateOfJournal: selected.dateOfJournal,
                            docFileName: selected.docFileName,
                            treatWith: '',
                        } : {...initialO, type: 'ohada-laws'}}
                />


            </div>
        </section>
    )
}

export default LawsList;


