import React, {useState} from "react"
import {useTranslation} from "gatsby-plugin-react-i18next";
import {getConnected} from "../../util/helper";

const LawsHeader = ({selectId=0, setSelected}: any) => {

    const {t} = useTranslation()
    let connected = getConnected()
    const [user, setUser] = useState(connected || null)

    return (
        <>
            <section className="wrapper image-wrapper bg-image bg-overlay text-white"
                     data-image-src="/assets/img/affaire.png">
                <div className="container pt-19 pt-md-21 pb-18 pb-md-20 text-center">
                    <div className="row">
                        <div className="col-md-10 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
                            <h1 className="display-1 text-white mb-3">{t('Ohadarb Laws')}</h1>
                            <p className="lead fs-lg px-md-3 px-lg-7 px-xl-8 px-xxl-2 ">
                                {t('HeaderInitText')}
                            </p>
                        </div>
                    </div>
                    { user?.id && user?.roles[0].name === 'ADMIN' &&
                        <div className="animate__animated animate__slideInUp animate__delay-3s">
                            <a href="#"
                               onClick={()=> setSelected(null)}
                               rel="noopener noreferrer"
                               data-bs-target={`#modal-add${selectId}`}
                               data-bs-toggle="modal"
                               className="btn btn-lg btn-outline-white rounded">
                                <span className="">{t('Add Ohadarb Law')}</span>
                                <i className="uil uil-plus-circle"
                                   style={{fontSize: "25px", margin: "0 5px",}}></i>
                            </a>
                        </div>}
                </div>
            </section>
        </>
    )
}

export default LawsHeader;
