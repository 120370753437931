export type ohadaData = {
    id: number | undefined,
    title: string,
    adoptedAt: string,
    type: string,
    place: string,
    journalNumber: string,
    // files: [],
    dateOfJournal: string,
    docFileName: string,
    treatWith: string,
}

export type CCJAData = {
    id: number | undefined,
    title: string,
    type: string,
    place: string,
    docFileName: string,
    treatWith: string,
    categoryId: string,
    summary: string,
    room: string,
    number: string,
    date: string,
    body: string,
}

export type CaseLawData = {
    id: number | undefined,
    title: string,
    categoryId: string,
    summary: string,
    room: string,
    number: string,
    date: string,
}

export type JournalData = {
    id: number | undefined,
    title: string,
    content: string,
    summary: string,
    authors: string,
    archived: boolean,
    keywords: string,
    authorId: number | undefined,
    lang: string,
    docFileName: string,

}


export const initialCCJA: CCJAData = {
    id: undefined,
    title: '',
    type: '',
    place: '',
    docFileName: '',
    treatWith: '',
    categoryId: '',
    summary: '',
    room: '',
    number: '',
    date: '',
    body: ''
}

export const initialArticle: JournalData = {
    id: undefined,
    title: '',
    content: '',
    summary: '',
    authors: '',
    archived: false,
    keywords: '',
    authorId: undefined,
    lang: 'en',
    docFileName: '',

}
export const initialArArticle: JournalData = {
    id: undefined,
    title: '',
    content: '',
    summary: '',
    authors: '',
    archived: true,
    keywords: '',
    authorId: undefined,
    lang: 'en',
    docFileName: '',

}
export const initialCaseLaw: CaseLawData = {
    id: undefined,
    title: '',
    categoryId: '',
    summary: '',
    room: '',
    number: '',
    date: '',

}

export const initialO = {
    id: undefined,
    title: '',
    adoptedAt: '',
    type: '',
    place: '',
    journalNumber: '',
    // files: [],
    dateOfJournal: '',
    docFileName: '',
    treatWith: '',
}
