import React, {useState} from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
import {getConnected} from "../../util/helper";

const CardType1 = ({
                       title,
                       adopted,
                       place,
                       journalNumber,
                       link,
                       lawKey,
                       selectId,
                       lawId,
                       getSelected
                   }: {
    title: string, adopted: string, place: string, journalNumber: string, link: string,
    selectId: number,
    lawKey: string, lawId: number, getSelected: Function
}) => {
    const {t} = useTranslation()
    let connected = getConnected()
    const [user, setUser] = useState(connected || null)

    return (
        <div className="card card-border-start border-primary m-2"
             style={{
                 maxWidth: '48%',
                 width: '100%'
             }}
             key={lawId}>
            <div className="card-body">
                <div className="d-flex flex-row justify-content-between">
                    <a className="text-decoration-none" href={link}>
                        <div className="d-flex flex-column flex-wrap flex-6">
                            <h5>{title}</h5>
                            <p className="px-2 py-0 m-1"> {t('adoptedAt')} ; {adopted} </p>
                            <p className="px-2 py-0 m-1"> {t('country')} : {place} </p>
                            <p className="px-2 py-0 m-1 "> {t('journal Number')} : {journalNumber} </p>
                        </div>
                    </a>
                    {user?.id && user?.roles[0].name === 'ADMIN' &&
                        <div className="d-flex flex-column flex-2 justify-content-start" style={{cursor: 'pointer'}}>
                            <i className="uil uil-edit fs-25 text-navy p-1 text-blue:hover"
                               data-bs-target={`#modal-add${selectId}`}
                               data-bs-toggle="modal"
                               onClick={() => {
                                   getSelected(lawId)
                               }}
                            ></i>
                            <i className="uil uil-trash-alt fs-25 text-ash p-1 text-blue:hover"
                               data-bs-target="#modal-delete"
                               data-bs-toggle="modal"
                               onClick={() => {
                                   getSelected(lawId)
                               }}
                            ></i>
                        </div>
                    }

                </div>
                <div className="d-flex flex-row justify-content-end text-decoration-none">
                    <i className="font-weight-bold fs-15">{lawKey}</i>
                </div>
            </div>
        </div>
    )
}


export default CardType1;
